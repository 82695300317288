
<template>
  <!-- 商品秒杀页 -->

  <div class="commodity-spike" v-show="timeList">
    <van-nav-bar
      title="秒杀列表"
      fixed
      left-text
      left-arrow
      @click-left="$goBack()"
      @touchmove.prevent
    />
    <div class="content">
      <div class="box">
        <!-- 秒杀时间线  -->

        <div class="list">
          <div class="list-box" ref="box">
            <div
              class="list-item"
              v-for="(item, index) in timeArr"
              :key="index"
              ref="time"
              @click="getgoodsArray(item)"
            >
              <!-- <div class="time">{{ item.hallTime + ":00" }}</div> -->
              <div class="time" v-if="item<5">{{ '0'+ (item) + ":00" }}</div>
              <div class="time" v-else>{{ item + ":00" }}</div>

              <div :class="[item ===active ? 'list-active' : '', 'state']">
                <span :class="['state-el',item===active? 'list-active' : '',]">
                  {{
                  item - new Date().getHours() === 0
                  ? "正在疯抢"
                  : item - new Date().getHours() > 0
                  ? "即将开始"
                  : "已结束"
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="line" v-show="timeList.length">
          <p></p>
          <span class="tips">{{ word.title }}</span>
          <p></p>
        </div>
        <!-- {{$route.query.session}} -->

        <div class="main">
          <div
            v-show="goodsArray.length===0"
            style="text-align:center; color:#999;margin-top:3rem;"
          >暂无商品</div>
          <van-list
            v-show="timeList.length"
            offset="50"
            v-model="loading"
            :finished="finished"
            finished-text="暂无更多商品"
            @load="onLoad"
            v-if="goodsArray.length"
          >
            <div class="warp">
              <div
                v-show="goodsArray"
                class="goods-list"
                v-for="(item, index) in goodsArray?goodsArray:0"
                :key="index"
              >
                <!-- 秒杀商品列表 -->
                <div class="goods-item fade-out-fwd" v-if="item">
                  <div class="title">
                    <!-- 用户点击跳转 传店铺id -->
                    <img
                      v-if="item.shopInfo"
                      @click="JumpStore(item.shopInfo.id)"
                      class="brand-logo"
                      :src="
                   item.shopInfo.imgUrl||'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fhbimg.b0.upaiyun.com%2F4c031e7d130cf22d2e000ea5f1ebedff10c540347637-OzK93U_fw658&refer=http%3A%2F%2Fhbimg.b0.upaiyun.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1632037409&t=15fd85ae51c87e698be596e27d4cba43'
                  "
                      alt="品牌logo"
                    />
                    <span class="store-name">{{item.shopInfo?item.shopInfo.name:'11' }}</span>
                  </div>

                  <div class="good-info">
                    <img class="good-img" :src="item.picUrl" alt="商品图片" />
                    <div class="desc">
                      <span class="good-name van-multi-ellipsis--l2">{{ item.name || ""}}</span>
                      <div class="prices">
                        <span class="activeprice">秒杀价：￥{{ item.seckillPrice }}</span>
                        <span class="orginprice">￥{{ item.goodsSku.salesPrice }}</span>
                      </div>
                      <span class="good-sell">
                        限量{{ item.limitNum }}件，已售{{
                        item.seckillNum
                        }}件
                      </span>
                      <div class="proportion">
                        <div
                          class="Proportion-item"
                          :style="{
                        width: getWidth(item.limitNum, item.seckillNum),
                      }"
                        ></div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="robbing"
                    @click="getId(item.seckillHallInfo.id)"
                  >{{ word.status ? "马上去抢" : "查看详情" }}</div>
                </div>
              </div>
            </div>
          </van-list>
          <!-- <div class="footer-tip" v-if="finished">没有更多了</div> -->
          <!-- <div class="footer-tip" v-if="goodsArray.length===0">暂无活动</div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getActiveTime,
  goodsList,
} from "../../api/commoditySpike/commoditySpike.js";
import smoothscroll from "smoothscroll-polyfill";
export default {
  name: "keep",
  data() {
    return {
      id: "",
      timeArr: [0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22],
      count: 0,
      isLoading: false,
      timeList: [], //时间列表
      goodsArray: [], //保存用户当前点击的时间点下的商品数据
      active: new Date().getHours(), //用户当前点击的时间点
      current: 1, //分页的页数
      size: 20,
      isShowSeckillList: false, //是否显示秒杀时刻表
      finished: false, //下拉加载是否已触底
      loading: false, //下拉加载的状态
      index: 0,
      word: {
        //保存用户点击秒杀场次的提示信息
        title: "",
        status: false,
      },
      timerId: 0, //保存当前活动场次的定时器id
    };
  },
  created() {
    this.finished = true;

    let session =
      this.$route.query.session || "684a1dbc-cd92-409d-893b-313797e8cee9";
    this.$store.commit("setSession", session);

    if (this.$route.query.session) {
      this.$store.commit("setSession", this.$route.query.session);
    }
    this.goodsArray = [];
    //获取秒杀活动场次

    let time =
      new Date().getFullYear() +
      "-" +
      (new Date().getMonth() + 1) +
      "-" +
      new Date().getDate();
    time = time.split("-");

    time = time.map((item) => {
      if (item - 0 < 10) {
        return "0" + item;
      }
      return item;
    });

    time = time[0] + "-" + time[1] + "-" + time[2];

    getActiveTime({ hallDate: time }).then((res) => {
      if (res.data.code === 0) {
        this.timeList = res.data.data.filter((item) => {
          let arr = item.hallDate.split("-");
          let data = new Date();
          if (
            arr[0] - data.getFullYear() === 0 &&
            arr[1] - data.getMonth() - 1 === 0 &&
            arr[2] - data.getDate() === 0
          ) {
            return item;
          }
        });
        if (this.active % 2 !== 0) {
          this.active++;
        }
        this.$nextTick(() => {
          document
            .querySelector(".list")
            .scrollTo(
              this.$refs.time[this.active / 2].scrollWidth *
                (this.active / 2 - 1),
              0
            );
        });

        try {
          this.timeList.forEach((item) => {
            //       // 寻找当前正在进行的活动场次信息
            // if (item.hallTime - new Date().getHours() === 0) {
            if (item.hallTime - this.active === 0) {
              this.id = item.id;
              //获取当前活动场次
              this.getTime();

              // this.getgoodsArray(this.active);

              throw new "退出循环"();
            }

            // 如果当前没有正在进行的活动 就寻找将要开启的活动场次信息

            if (item.hallTime - new Date().getHours() > 0) {
              this.active = new Date().getHours() + 1;

              this.id = item.id;
              this.getTime();
              this.$nextTick(() => {
                document
                  .querySelector(".list")
                  .scrollTo(
                    this.$refs.time[1].scrollWidth * (this.active / 2 - 1),
                    0
                  );
                this.getWord(item.id);
              });
              throw new "退出循环"();
            }
            this.getWord();
          });
        } catch (error) {
          //
        }
      }
    });
  },
  methods: {
    // 下拉刷新

    // onRefresh() {
    //   if (this.loading) {
    //     return;
    //   }
    //   this.current = 1;
    //   this.goodsArray = [];
    //   let id = JSON.parse(sessionStorage.getItem("timeID"));
    //   this.getTime(id, "refresh");
    // },

    // 上拉加载
    onLoad() {
      if (this.goodsArray.length % this.size !== 0) {
        this.finished = true;
        return false;
      }
      this.current++;
      this.getTime();
      let id = JSON.parse(sessionStorage.getItem("timeID")) || "";

      if (!id) {
        this.loading = false;
        return;
      }
      this.current++;
      this.getTime();
    },
    //获取当前用户点击的活动场次信息
    getWord() {
      clearInterval(this.timerId);
      if (this.active - new Date().getHours() === 0) {
        this.word.title =
          "本场还剩:" +
          (60 - (new Date().getSeconds() === 0)
            ? 59 - new Date().getMinutes()
            : 60 - new Date().getMinutes()) +
          "分" +
          (60 - new Date().getSeconds() + "秒  结束");
        this.word.status = true;
        //显示当前进行的活动倒计时

        this.timerId = setInterval(() => {
          this.word.title =
            "本场还剩:" +
            (60 - (new Date().getSeconds() === 0)
              ? 59 - new Date().getMinutes() < 10
                ? "0" + (59 - new Date().getMinutes())
                : 59 - new Date().getMinutes()
              : 60 - new Date().getMinutes() < 10
              ? "0" + (60 - new Date().getMinutes())
              : 60 - new Date().getMinutes()) +
            "分" +
            (60 - new Date().getSeconds() + "秒  结束");
          if (60 - new Date().getMinutes() <= 0) {
            this.getWord();
          }
        }, 1000);
        return;
      }

      if (this.active - new Date().getHours() < 0) {
        this.word.title = "本场已结束";
        this.word.status = false;
        return;
      }
      if (this.active - new Date().getHours() > 0) {
        this.word.title = "即将开始";
        this.word.status = false;

        return;
      }
    },
    //用户点击活动时间 展示不同时间段的商品
    getgoodsArray(item) {
      this.current = 1;
      this.finished = false;
      this.active = item;
      this.getWord();
      this.goodsArray = [];

      this.timeList.forEach((item) => {
        if (item.hallTime - this.active === 0) {
          this.id = item.id;
          this.getTime();
        }
      });

      if (this.active === 0) {
        document.querySelector(".list").scrollTo({
          left:
            this.$refs.time[this.active / 2].scrollWidth * (this.active / 2),
          top: 0,
          behavior: "smooth",
        });
      } else {
        document.querySelector(".list").scrollTo({
          left:
            this.$refs.time[this.active / 2 - 1].scrollWidth *
            (this.active / 2 - 1),
          top: 0,
          behavior: "smooth",
        });
      }

      // this.getTime();
    },

    //获取用户点击的场次信息 并返回该场次的活动产品数据
    getTime() {
      // sessionStorage.setItem("timeID", JSON.stringify(id));
      this.isShowSeckillList = false;
      // this.active = id;
      this.getWord();

      // this.
      // this.timeList.forEach(item=>{

      // })

      goodsList({
        current: this.current,
        size: this.size,
        seckillHallId: this.id,
      })
        .then((res) => {
          if (res.data.code === 0) {
            this.goodsArray = [...this.goodsArray, ...res.data.data.records];
            if (res.data.data.records.length < this.size) {
              this.isShowSeckillList = true;
              this.finished = true;
            }
            this.isLoading = false;
            this.loading = false;
          }
        })
        .finally(() => {
          this.isShowSeckillList = false;
        })
    },

    getId(id) {
      //   获取用户点击的商品id 并跳转到相应的页面
      this.$router.push({
        path: "/seckillDatil",
        query: {
          id: id,
          session: this.$route.query.session,
        },
      });
    },
    JumpStore(id) {
      console.log(id);
      //获取店铺id 并跳转到店铺首页
    },
    //进度条
    getWidth(num, sell) {
      return ((sell * 100) / num).toFixed(2) + "%";
    },
    setRem() {
      let screenWidth = document.documentElement.getBoundingClientRect().width;
      document.documentElement.style.fontSize = screenWidth / 10 + "px";
    },
  },
  activated() {
    this.$nextTick(() => {
      document
        .querySelector(".list")
        .scrollTo(this.$refs.time[1].scrollWidth * (this.active / 2 - 1), 0);
    });

    this.setRem();
  },
  mounted() {
    this.setRem();
    window.addEventListener("orientationchange", this.setRem);
    window.addEventListener("resize", this.setRem);
    smoothscroll.polyfill();
  },

  beforeDestroy() {
    document.documentElement.style.fontSize = "16px";
    clearInterval(this.timerId);
  },
};
</script>




<style lang="less" scoped>
.commodity-spike {
  height: 100vh;
  overflow: hidden;
  box-sizing: border-box;
  padding-top: 1.226667rem;
  position: fixed;
  width: 100%;
  /deep/ .van-overlay {
    z-index: 1000 !important;
  }
}
.head {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  box-sizing: border-box;
  background-color: white;
  border-bottom: 1px solid #ccc;
  display: flex;
  padding: 0.266667rem;
  align-items: center;
}
.head .back {
  width: 0.666667rem;
  height: 0.64rem;
}
.head .title {
  font-size: 0.453333rem;
  font-weight: 600;
  margin: auto;
  padding-right: 0.666667rem;
}

.content {
  width: 100%;
  height: 100%;

  background-color: #e64d42ff;

  background-color: #e64d42ff;
}
.content::-webkit-scrollbar {
  display: none;
}
.main {
  flex: 1;
  overflow: hidden;
  box-sizing: border-box;
}
.content .box {
  background-image: url("../../assets/img/commoditySpike/miaosha.png");
  background-position: center top;
  background-size: 100%;
  background-repeat: no-repeat;
  padding-top: 5.8rem;
  overflow: hidden;
  height: 100%;
  background-color: #e64d42ff;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.content .box::-webkit-scrollbar {
  display: none;
}
.content .box::-webkit-scrollbar {
  display: none;
}
.warp {
  margin-bottom: 0.5rem;
  padding-bottom: 0.8rem;
}
.line {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}
.line p {
  display: flex;

  height: 0.013333rem;
  width: 1.666667rem;
  background-color: #999;
}

.content .list {
  position: absolute;
  top: 4.773333rem;
  left: 0;

  width: 9.333333rem;
  height: 1.12rem;
  margin: auto;
  align-items: center;
  color: white;
  margin-bottom: 0.293333rem;
  overflow-x: auto;
  box-shadow: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  /* position: relative; */
  box-sizing: border-box;
}

.content .list::-webkit-scrollbar {
  display: none;
}
.list-box {
  transition: 1s linear;
  display: flex;
  align-items: center;
  width: 9.333333rem;
  box-sizing: border-box;
}
.content .list .list-item {
  display: flex;
  flex-direction: column;
  width: 2.5rem;
  text-align: center;
  width: 500px;
  padding: 0 20px;
}

.content .list .time {
  text-align: center;
  line-height: 100%;
  font-size: 0.32rem;
}
.content .list .state {
  display: flex;
  align-items: center;
  justify-content: center;

  line-height: 100%;
  font-size: 0.266667rem;
  margin-top: 0.213333rem;
  width: 1.386667rem;
  margin: auto;
}
.content .list .state-el {
  width: 1.386667rem;
  display: block;
  font-size: 0.266667rem;
  transform: scale(0.83);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0.426667rem;
}
.list-active {
  display: block;
  color: #e64d42;

  background: #ffffff;
  border-radius: 0.213333rem;
}
.tips {
  display: inline-block;
  padding: 0 0.133333rem;
  font-size: 0.4rem;
  transform: scale(0.83);
  color: white;
}
.goods-list {
  padding-top: 0.26rem;
}

.goods-item {
  width: 9.146667rem;
  background-color: white;
  border-radius: 0.133333rem;
  margin: 0 auto 0.266667rem;
  padding: 0.373333rem 0.426667rem;

  box-sizing: border-box;
  margin-bottom: 0.533333rem;
}
.goods-item .title {
  display: flex;
  align-items: center;
  margin-bottom: 0.693333rem;
}
.title .brand-logo {
  width: 0.666667rem;
  height: 0.666667rem;
  margin-right: 0.213333rem;
  border-radius: 0.133333rem;
}
.title .store-name {
  font-size: 0.4rem;
  font-weight: 600;
}
.van-list {
  font-size: 0.32rem;
}
.good-info {
  display: flex;
  font-size: 0.4rem;
}
.good-info .good-img {
  width: 2.426667rem;
  height: 2.426667rem;
  border-radius: 0.266667rem;
  box-sizing: border-box;
  margin-right: 0.266667rem;
}
.desc .good-name {
  font-weight: 600;
}
.desc .activeprice {
  color: #a80000;
  font-weight: 600;
  margin-right: 0.266667rem;
}
.desc .orginprice {
  color: #999;
  text-decoration: line-through;
  font-weight: 600;
  margin-bottom: 0.133333rem;
}
.desc .good-sell {
  font-size: 0.32rem;
  color: #333;
  margin-bottom: 0.293333rem;
}
.desc .proportion {
  width: 5.6rem;
  height: 0.133333rem;
  background-color: #eaeef5;
  border-radius: 0.213333rem;
  overflow: hidden;
  margin-top: 0.293333rem;
}
.proportion .Proportion-item {
  width: 1.28rem;
  height: 0.133333rem;
  background-color: #e64d42;
  border-radius: 0.213333rem;
}
.robbing {
  width: 8.293333rem;
  height: 0.986667rem;
  display: flex;
  align-items: center;
  justify-content: center;

  color: white;
  font-size: 0.4rem;
  font-weight: 600;
  background-image: url("../../assets/img/commoditySpike/btn.png");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-top: 0.533333rem;
}
.fade-out-fwd {
  -webkit-animation: fade-out-fwd 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: fade-out-fwd 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes fade-out-fwd {
  0% {
    -webkit-transform: translateZ(100);
    transform: translateZ(100);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(80px);
    transform: translateZ(80px);
    opacity: 1;
  }
}
@keyframes fade-out-fwd {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(80px);
    transform: translateZ(80px);
    opacity: 1;
  }
}
/deep/ .van-list {
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
}
</style>