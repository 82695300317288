import {fetchGet} from '../../router/https'

// 秒杀会场时间列表查询
function getActiveTime(data){
  return fetchGet('/mallapp/seckillhall/list',data)
}

// 秒杀分页列表
function goodsList(data){
  return fetchGet('/mallapp/seckillinfo/page',data)
}

export{
   getActiveTime,
  goodsList
}